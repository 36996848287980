import React from "react"
import * as S from "./article-hero.styles"
import parse from "html-react-parser"
import { useMediaQuery, useTheme } from "@mui/material"

const ArticleHero = ({ image, category, title, author, date, logoImage }) => {
  const theme = useTheme()
  const isMD = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <S.Section>
      {isMD ? (
        <>
          <S.Image img={image} alt="articleBg" />
          <S.Content>
            {category && <S.Category>{category}</S.Category>}
            <S.Title>{parse(title)}</S.Title>
            {author && (
              <S.Author>
                By {author}, {date}
              </S.Author>
            )}
          </S.Content>
          {logoImage && (
            <S.LogoDiv>
              <S.Logo img={logoImage} alt="logo" />
            </S.LogoDiv>
          )}
        </>
      ) : (
        <>
          <S.BgImage img={image} alt="articleBg">
            <S.Gradient>
              <S.Content>
                {category && <S.Category>{category}</S.Category>}
                <S.Title>{parse(title)}</S.Title>
                {author && (
                  <S.Author>
                    By {author}, {date}
                  </S.Author>
                )}
              </S.Content>
            </S.Gradient>
          </S.BgImage>
          {logoImage && (
            <S.LogoDiv>
              <S.Logo img={logoImage} alt="logo" />
            </S.LogoDiv>
          )}
        </>
      )}
    </S.Section>
  )
}

export default ArticleHero

import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import CustomBgImage from "../../components/custom-bg-image/custom-bg-image.component"
import CustomImage from "../../components/custom-image/custom-image.component"

export const Section = styled(SectionWrapper)`
  padding: 0;
`
export const BgImage = styled(CustomBgImage)`
  height: 652px;
  width: 100%;
  ${({ theme }) => theme.breakpoints.down("md")} {
    max-height: 300px;
  }
`

export const Gradient = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
`
export const Content = styled.div`
  position: absolute;
  bottom: 0;
  padding: 0 1rem;
  ${({ theme }) => theme.breakpoints.down("md")} {
    position: relative;
  }
`
export const Category = styled.p`
  color: ${({ theme }) => theme.palette.secondary.main};
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.typography.pxToRem(12)};
  font-family: ${({ theme }) => theme.fonts.primary};
  letter-spacing: ${({ theme }) => theme.typography.pxToRem(1)};
  text-align: center;
`
export const Title = styled.h2`
  max-width: 1000px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  line-height: ${({ theme }) => theme.typography.pxToRem(36)};
  font-weight: 500;
  text-align: center;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1rem;
    line-height: normal;
  }
`
export const Author = styled.p`
  color: ${({ theme }) => theme.palette.text.content};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  text-align: center;
  padding-bottom: 2rem;
`
export const LogoDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 2em;
  .gatsby-image-wrapper {
    max-height: 90px;
    min-height: 90px;
    img {
      object-fit: contain !important;
    }
  }
`
export const Logo = styled(CustomImage)`
  width: 100%;
  height: 100%;
`
export const Image = styled(CustomImage)`
  max-height: 300px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    img {
      max-height: 300px;
    }
  }
`

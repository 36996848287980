import React from "react"
import Layout from "../components/layout"
import MiscContent from "../layouts/MiscContent"
import { LayoutTitle } from "../styles/app.styles"
import PartnersHero from "../layouts/PartnersHero/partners-hero.component"
import { graphql, useStaticQuery } from "gatsby"
import ForgeHero from "../layouts/ForgeHero"
import TopBrandsInfoBanner from "../layouts/TopBrandsInfoBanner"
import RobotImageDescription from "../layouts/RobotImageDescription"
import SoftwarePlatform from "../layouts/SoftwarePlatform"
import AboutSection from "../layouts/AboutSection"
import LeadershipTeam from "../layouts/LeadershipTeam/"
import SalesCarrousel from "../layouts/SalesCarrousel/"
import CenteredMiscContent from "../layouts/CenteredMiscContent"
import ImageInfoRow from "../layouts/ImageInfoRow"
import InfoRow from "../layouts/InfoRow"
import ReadyBanner from "../layouts/ReadyBanner"
import ServiceDescription from "../layouts/ServiceDescription"
import Careers from "../layouts/Careers"
import CaseStudiesGrid from "../layouts/CaseStudiesGrid"
import RelatedArticles from "../layouts/RelatedArticles"
import ArticlesPage from "../layouts/ArticlesPage"
import ArticleHero from "../layouts/ArticleHero"
import DeveloperDescription from "../layouts/DeveloperDescription"
import ProductivityDescription from "../layouts/ProductivityDescription"
import DescriptionAccordion from "../layouts/DescriptionAccordion"
import PlacesMapGrid from "../layouts/PlacesMapGrid"
import StatisticBox from "../layouts/StatisticBox"
import UseDescription from "../layouts/UseDescription"
import RobotsUsability from "../layouts/RobotsUsability"
import DeviceControl from "../layouts/DeviceControl"
import AppMarketplace from "../layouts/AppMarketplace"
import LocationSlider from "../layouts/LocationSlider"
import Highlights from "../layouts/Highlights"
import HeroForm from "../layouts/HeroForm"
import ForgeOsHero from "../layouts/ForgeOSHero"
import ForgeApps from "../layouts/ForgeApps"
import ForgeDemo from "../layouts/ForgeDemo"
import ForgeTabs from "../layouts/ForgeTabs"
import DeviceControlSection from "../layouts/DeviceControlSection"
import HomeHero from "../layouts/HomeHero"
import ForgeExperienceSection from "../layouts/Forge-Experience-Section"
import EmpowerVideoSection from "../layouts/EmpowerVideoSection"
import EmpowerSection from "../layouts/EmpowerSection"
import HomePlatformSection from "../layouts/HomePlatformSection"
import PlatformVideoSection from "../layouts/PlatformVideoSection"
import CardsGrid from "../layouts/CardsGrid"
import ContactBanner from "../layouts/ContactBanner"
import PlatformSection from "../layouts/PlatformSection"
import TabletSection from "../layouts/TabletSection"
import RobotsSlider from "../layouts/Robots Slider"
import ProductivityGrid from "../layouts/ProductivityGrid"
import DeploymentSection from "../layouts/DeploymentSection"
import ResultsRow from "../layouts/ResultsRow"
import Testimonials from "../layouts/Testimonials"
import ForgeVideoHero from "../layouts/ForgeVideoHero"
import ForgeVideo from "../layouts/ForgeVideo"
import ForgeGrid from "../layouts/ForgeGrid"
import BannerDescription from "../layouts/BannerDescription"
import ReadyContent from "../layouts/ReadyContent"

const Layouts = () => {
  const placeholderText =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras lobortis velit urna, at rutrum nunc auctor et. Pellentesque pharetra neque aliquam lorem tempus, non dapibus ex accumsan. Ut sed nisl non nulla efficitur tempor. Vestibulum sit amet mauris ac dolor dapibus bibendum a quis metus."
  const staticQuery = useStaticQuery(graphql`
    query {
      img: file(relativePath: { eq: "columbus-image.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      kawasaki: file(relativePath: { eq: "kawasaki.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      hero: file(relativePath: { eq: "hero.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      imgLeadership: file(relativePath: { eq: "team-member.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      bg: file(relativePath: { eq: "font.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      robotImage: file(relativePath: { eq: "robot-oem-module.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      mapImg: file(relativePath: { eq: "map-ohaio.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      aboutImage: file(relativePath: { eq: "robot-oem-module.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  const robots = [
    {
      image: staticQuery.img,
      title: "Usability Attracts More Customers",
      description:
        "By integrating your robots with Forge/OS, you gain access to the Forge productivity apps like Task Canvas, " +
        "which enables anyone on the production floor to control your robot, manage changeover, touch up waypoints, program " +
        "for new parts, and troubleshoot automation. This makes your robots more appealing to manufacturers who are trying to " +
        "solve their skilled labor shortage.",
    },
    {
      image: staticQuery.img,
      title: "Sell More Robots by Making Your Robots Easy to Use",
      description:
        "Forge/OS brings cobot ease-of-use to industrial robots so your users can choose the right robot for the task. " +
        "With manufacturers hungry to deploy more automation, Forge/OS makes your robots easy enough for anyone on the production " +
        "floor to use and will help accelerate sales.",
    },
    {
      image: staticQuery.img,
      title: "Enhance Your Robots’ Usability with Custom Apps",
      description:
        "The Forge SDK facilitates the development of custom applications, running on Forge/OS, that help end users get more value " +
        "from your robots. Create task-specific apps that make it easier for end users to program tasks like palletizing, dispensing, " +
        "material handling, inspection, etc.",
    },
  ]

  const buttonItems = [
    {
      button: {
        title: "Developer",
        url: "./",
      },
    },
    {
      button: { title: "OEMs and Solution Builders", url: "./" },
    },
    {
      button: { title: "Enterprise Manufacturers", url: "./" },
    },
  ]

  const buttonObj = { title: "test", url: "test", target: "" }

  const relatedArticles = [
    {
      image: staticQuery.imgLeadership,
      title: "Enhance Your Robots’ Usability with Custom Apps",
      category: "Robots",
      url: "./",
    },
    {
      image: staticQuery.img,
      title: "Enhance Your Robots’ Usability with Custom Apps",
      category: "Robots",
      url: "./",
    },
    {
      image: staticQuery.img,
      title: "Enhance Your Robots’ Usability with Custom Apps",
      category: "Robots",
      url: "./",
    },
  ]

  const articles = {
    featuredArticle: {
      postImg: staticQuery.aboutImage,
      tagName: "Featured",
      categoryName: "CATEGORY here",
      postTitle:
        "Achieving Smooth Digital Transformations in Manufacturing Facilities",
      content: `Digital transformations can have significant upside for the businesses that undertake them:
      improved efficiency, increased output, reduced costs, and more stable operations to name a few.
      While they are becoming increasingly common in the technology sector, they remain rare among businesses with
      physical production environments. Why? Because digital transformations can be very challenging in physical
      production environments – like manufacturing facilities.`,
    },
    otherArticles: [
      {
        image: staticQuery.imgLeadership,
        title: "Enhance Your Robots’ Usability with Custom Apps 1",
        category: "Robots",
        url: "./",
      },
      {
        image: staticQuery.imgLeadership,
        title: "Enhance Your Robots’ Usability with Custom Apps 2",
        category: "Robots",
        url: "./",
      },
      {
        image: staticQuery.imgLeadership,
        title: "Enhance Your Robots’ Usability with Custom Apps 3",
        category: "Robots",
        url: "./",
      },
      {
        image: staticQuery.imgLeadership,
        title: "Enhance Your Robots’ Usability with Custom Apps 4",
        category: "Robots",
        url: "./",
      },
      {
        image: staticQuery.imgLeadership,
        title: "Enhance Your Robots’ Usability with Custom Apps 5",
        category: "Robots",
        url: "./",
      },
      {
        image: staticQuery.imgLeadership,
        title: "Enhance Your Robots’ Usability with Custom Apps 6",
        category: "Robots",
        url: "./",
      },
      {
        image: staticQuery.imgLeadership,
        title: "Enhance Your Robots’ Usability with Custom Apps 7",
        category: "Robots",
        url: "./",
      },
      {
        image: staticQuery.imgLeadership,
        title: "Enhance Your Robots’ Usability with Custom Apps 8",
        category: "Robots",
        url: "./",
      },
      {
        image: staticQuery.imgLeadership,
        title: "Enhance Your Robots’ Usability with Custom Apps 9",
        category: "Robots",
        url: "./",
      },
      {
        image: staticQuery.imgLeadership,
        title: "Enhance Your Robots’ Usability with Custom Apps 10",
        category: "Robots",
        url: "./",
      },
      {
        image: staticQuery.imgLeadership,
        title: "Enhance Your Robots’ Usability with Custom Apps 11",
        category: "Robots",
        url: "./",
      },
      {
        image: staticQuery.imgLeadership,
        title: "Enhance Your Robots’ Usability with Custom Apps 12",
        category: "Robots",
        url: "./",
      },
      {
        image: staticQuery.imgLeadership,
        title: "Enhance Your Robots’ Usability with Custom Apps 13",
        category: "Robots",
        url: "./",
      },
    ],
  }

  return (
    <Layout
      seo={{
        title: "Layouts - ONLY FOR DEV",
        metaRobotsNoindex: "noindex",
        metaRobotsNofollow: "nofollow",
      }}
    >
      <LayoutTitle>MiscContent</LayoutTitle>
      <MiscContent content={`<p>Some content Here</p>`} />
      <LayoutTitle>Device Control Section</LayoutTitle>
      <DeviceControlSection
        title="Section Title"
        description="Section Description"
        iconImage={staticQuery.img}
        tabs={[
          { title: "Tab1", image: staticQuery.img, youtubeId: "HFfdrkIxStU" },
          { title: "Tab2", image: staticQuery.bg, youtubeId: "HFfdrkIxStU" },
        ]}
      />
      <LayoutTitle>Home Hero</LayoutTitle>
      <HomeHero title="Section Title" />
      <LayoutTitle>Forge Experience Section</LayoutTitle>
      <ForgeExperienceSection
        button={{ url: "/", title: "button title" }}
        description="Section Description"
        bottomDescription="Bottom description"
        title="Section Title"
      />
      <LayoutTitle>Empower Video Section</LayoutTitle>
      <EmpowerVideoSection
        button={{ url: "/", title: "button title" }}
        description="Section Description"
        title="Section Title"
      />
      <LayoutTitle>Empower Section</LayoutTitle>
      <EmpowerSection
        button={{ url: "/", title: "button title" }}
        description="Section Description"
        title="Section Title"
        image={staticQuery.img}
      />
      <LayoutTitle> Home Platform Section</LayoutTitle>
      <HomePlatformSection
        image={staticQuery.img}
        description="Section Description"
        title="Section Title"
      />
      <LayoutTitle> Platform Video Section</LayoutTitle>
      <PlatformVideoSection
        description="Section Description"
        title="Section Title"
      />
      <LayoutTitle>Cards Grid</LayoutTitle>
      <CardsGrid
        cards={[
          {
            description: "Section Description 1",
            title: "Section Title 1",
            image: staticQuery.img,
          },
          {
            description: "Section Description 2",
            title: "Section Title 2",
            image: staticQuery.img,
          },
          {
            description: "Section Description 3",
            title: "Section Title 3",
            image: staticQuery.img,
          },
        ]}
      />
      <LayoutTitle>Contact Banner</LayoutTitle>
      <ContactBanner />
      <LayoutTitle>Platform Section</LayoutTitle>
      <PlatformSection
        button={{ url: "/", title: "button title" }}
        description="Section Description"
        title="Section Title"
        backgroundImage={staticQuery.img}
      />
      <LayoutTitle>Platform Section (orientation=right)</LayoutTitle>
      <PlatformSection
        button={{ url: "/", title: "button title" }}
        description="Section Description"
        title="Section Title"
        backgroundImage={staticQuery.img}
        orientation="right"
      />

      <LayoutTitle>Tablet Section</LayoutTitle>
      <TabletSection
        image={staticQuery.img}
        button={{ url: "/", title: "button title" }}
        description="Section Description"
        title="Section Title"
      />
      <LayoutTitle>Tablet Section (reverse)</LayoutTitle>
      <TabletSection
        image={staticQuery.img}
        button={{ url: "/", title: "button title" }}
        description="Section Description"
        title="Section Title"
        reverse
      />

      <LayoutTitle>Robots Slider</LayoutTitle>
      <RobotsSlider
        title="test"
        description="test"
        robots={[
          {
            logo: "https://admin-rr.aftershock.agency/wp-content/uploads/2022/01/staubli-black.png",
            image: staticQuery.img,
          },
          {
            logo: "https://admin-rr.aftershock.agency/wp-content/uploads/2022/01/staubli-black.png",
            image: staticQuery.img,
          },
          {
            logo: "https://admin-rr.aftershock.agency/wp-content/uploads/2022/01/staubli-black.png",
            image: staticQuery.img,
          },
          {
            logo: "https://admin-rr.aftershock.agency/wp-content/uploads/2022/01/staubli-black.png",
            image: staticQuery.img,
          },
        ]}
      />

      <LayoutTitle>Productivity Grid</LayoutTitle>
      <ProductivityGrid
        leftImage={staticQuery.img}
        title="text"
        description="Achieve new levels of productivity and efficiency with Forge/OS Apps that power hardware and software from leading automation brands, or purpose-built apps that simplify tasks like palletizing, inspection and machine tending."
        features={[
          {
            title: "taskt canvas",
            description:
              "Accelerate programming and empower anyone on the production floor to control automation.",
            image:
              "https://admin-rr.aftershock.agency/wp-content/uploads/2022/01/icon_task_canvas@15x.svg",
            shadowColor: "#08BC5B",
          },
          {
            title: "taskt canvas",
            description:
              "Accelerate programming and empower anyone on the production floor to control automation.",
            image:
              "https://admin-rr.aftershock.agency/wp-content/uploads/2022/01/icon_task_canvas@15x.svg",
            shadowColor: "#08BC5B",
          },
          {
            title: "taskt canvas",
            description:
              "Accelerate programming and empower anyone on the production floor to control automation.",
            image:
              "https://admin-rr.aftershock.agency/wp-content/uploads/2022/01/icon_task_canvas@15x.svg",
            shadowColor: "#08BC5B",
          },
          {
            title: "taskt canvas",
            description:
              "Accelerate programming and empower anyone on the production floor to control automation.",
            image:
              "https://admin-rr.aftershock.agency/wp-content/uploads/2022/01/icon_task_canvas@15x.svg",
            shadowColor: "#08BC5B",
          },
        ]}
        button={{ title: "test", url: "test" }}
      />

      <LayoutTitle>Deployment Section</LayoutTitle>
      <DeploymentSection
        title="Text"
        button={{ title: "test", url: "test" }}
        description="Jumpstart your next deployment with a complete robotic solution that includes robotic arm, end of arm tooling, parts presentation, safety, and Forge/OS to control it all."
      />

      <LayoutTitle>Results Row</LayoutTitle>
      <ResultsRow
        topTitle="test"
        middleTitle="test"
        leftTitle="test"
        description="With Forge/OS your process experts are empowered to program and manage automation, and machine operators can recover a system that goes down on the third shift. When you own your automation, downtime is a thing of the past."
        image={staticQuery.img}
      />

      <LayoutTitle>Testimonials</LayoutTitle>
      <Testimonials
        testimonials={[
          {
            description:
              "Our Forge/OS-powered solution solved a critical chokepoint in our ventilator component manufacturing, allowing us to meet COVID-19-related demand that would have been impossible before.",
            author: "test",
            company: "test",
            role: "test",
          },
          {
            description:
              "Our Forge/OS-powered solution solved a critical chokepoint in our ventilator component manufacturing, allowing us to meet COVID-19-related demand that would have been impossible before.",
            author: "test",
            company: "test",
            role: "test",
          },
          {
            description:
              "Our Forge/OS-powered solution solved a critical chokepoint in our ventilator component manufacturing, allowing us to meet COVID-19-related demand that would have been impossible before.",
            author: "test",
            company: "test",
            role: "test",
          },
        ]}
      />

      <LayoutTitle>Testimonials (Title + Description)</LayoutTitle>
      <Testimonials
        testimonials={[
          {
            description:
              "Our Forge/OS-powered solution solved a critical chokepoint in our ventilator component manufacturing, allowing us to meet COVID-19-related demand that would have been impossible before.",
            author: "test",
            company: "test",
            role: "test",
          },
          {
            description:
              "Our Forge/OS-powered solution solved a critical chokepoint in our ventilator component manufacturing, allowing us to meet COVID-19-related demand that would have been impossible before.",
            author: "test",
            company: "test",
            role: "test",
          },
          {
            description:
              "Our Forge/OS-powered solution solved a critical chokepoint in our ventilator component manufacturing, allowing us to meet COVID-19-related demand that would have been impossible before.",
            author: "test",
            company: "test",
            role: "test",
          },
        ]}
        title="test"
        description="With Forge/OS your process experts are empowered to program and manage automation, and machine operators can recover a system that goes down on the third shift. When you own your automation, downtime is a thing of the past."
      />
      <LayoutTitle>About Section</LayoutTitle>
      <AboutSection
        title="test"
        description="test"
        youtubeId="GVpPWEo8Wig"
        videoImage={staticQuery.img}
      />

      <LayoutTitle>Centered Misc Content (Small)</LayoutTitle>
      <CenteredMiscContent
        containerMaxWidth="sm"
        content="<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod vel erat id tincidunt. Ut lobortis neque in mauris aliquet tempus sit amet non urna. Integer rhoncus mauris in metus rhoncus tempor. Nam nec elementum ipsum. Nunc tempus lectus a varius tincidunt. Sed congue sollicitudin mi non aliquet. Nunc tellus lectus, bibendum non semper sit amet, mattis eu lacus.</p>"
      />

      <LayoutTitle>Centered Misc Content (Medium)</LayoutTitle>
      <CenteredMiscContent
        containerMaxWidth="md"
        content="<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod vel erat id tincidunt. Ut lobortis neque in mauris aliquet tempus sit amet non urna. Integer rhoncus mauris in metus rhoncus tempor. Nam nec elementum ipsum. Nunc tempus lectus a varius tincidunt. Sed congue sollicitudin mi non aliquet. Nunc tellus lectus, bibendum non semper sit amet, mattis eu lacus.</p>"
      />

      <LayoutTitle>Centered Misc Content (Large)</LayoutTitle>
      <CenteredMiscContent
        containerMaxWidth="lg"
        content="<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod vel erat id tincidunt. Ut lobortis neque in mauris aliquet tempus sit amet non urna. Integer rhoncus mauris in metus rhoncus tempor. Nam nec elementum ipsum. Nunc tempus lectus a varius tincidunt. Sed congue sollicitudin mi non aliquet. Nunc tellus lectus, bibendum non semper sit amet, mattis eu lacus.</p>"
      />

      <LayoutTitle>Centered Misc Content (Extra-large)</LayoutTitle>
      <CenteredMiscContent
        containerMaxWidth="xl"
        content="<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod vel erat id tincidunt. Ut lobortis neque in mauris aliquet tempus sit amet non urna. Integer rhoncus mauris in metus rhoncus tempor. Nam nec elementum ipsum. Nunc tempus lectus a varius tincidunt. Sed congue sollicitudin mi non aliquet. Nunc tellus lectus, bibendum non semper sit amet, mattis eu lacus.</p>"
      />

      <LayoutTitle>Centered Misc Content (No Max Width)</LayoutTitle>
      <CenteredMiscContent
        containerMaxWidth={false}
        content="<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod vel erat id tincidunt. Ut lobortis neque in mauris aliquet tempus sit amet non urna. Integer rhoncus mauris in metus rhoncus tempor. Nam nec elementum ipsum. Nunc tempus lectus a varius tincidunt. Sed congue sollicitudin mi non aliquet. Nunc tellus lectus, bibendum non semper sit amet, mattis eu lacus.</p>"
      />

      <LayoutTitle>Leadership Team</LayoutTitle>
      <LeadershipTeam
        sectionTitle="test"
        teamMembers={[
          {
            image: staticQuery.imgLeadership,
            name: "Ben Gibbs",
            occupation: "Founder",
            linkedinUrl: "https://www.linkedin.com/in/benjamingibbs/",
          },
          {
            image: staticQuery.imgLeadership,
            name: "Ben Gibbs",
            occupation: "Founder",
            linkedinUrl: "https://www.linkedin.com/in/benjamingibbs/",
          },
          {
            image: staticQuery.imgLeadership,
            name: "Ben Gibbs",
            occupation: "Founder",
            linkedinUrl: "https://www.linkedin.com/in/benjamingibbs/",
          },
          {
            image: staticQuery.imgLeadership,
            name: "Ben Gibbs",
            occupation: "Founder",
            linkedinUrl: "https://www.linkedin.com/in/benjamingibbs/",
          },
          {
            image: staticQuery.imgLeadership,
            name: "Ben Gibbs",
            occupation: "Founder",
            linkedinUrl: "https://www.linkedin.com/in/benjamingibbs/",
          },
          {
            image: staticQuery.imgLeadership,
            name: "Ben Gibbs",
            occupation: "Founder",
            linkedinUrl: "https://www.linkedin.com/in/benjamingibbs/",
          },
          {
            image: staticQuery.imgLeadership,
            name: "Ben Gibbs",
            occupation: "Founder",
            linkedinUrl: "https://www.linkedin.com/in/benjamingibbs/",
          },
          {
            image: staticQuery.imgLeadership,
            name: "Ben Gibbs",
            occupation: "Founder",
            linkedinUrl: "https://www.linkedin.com/in/benjamingibbs/",
          },
          {
            image: staticQuery.imgLeadership,
            name: "Ben Gibbs",
            occupation: "Founder",
            linkedinUrl: "https://www.linkedin.com/in/benjamingibbs/",
          },
          {
            image: staticQuery.imgLeadership,
            name: "Ben Gibbs",
            occupation: "Founder",
            linkedinUrl: "https://www.linkedin.com/in/benjamingibbs/",
          },
          {
            image: staticQuery.imgLeadership,
            name: "Ben Gibbs",
            occupation: "Founder",
            linkedinUrl: "https://www.linkedin.com/in/benjamingibbs/",
          },
          {
            image: staticQuery.imgLeadership,
            name: "Ben Gibbs",
            occupation: "Founder",
            linkedinUrl: "https://www.linkedin.com/in/benjamingibbs/",
          },
        ]}
      />

      <LayoutTitle>Partners Hero</LayoutTitle>
      <PartnersHero
        title="title"
        image={staticQuery.img}
        description="test"
        button={{ title: "test", url: "url" }}
      />

      <LayoutTitle>Partners Hero (Title + Button)</LayoutTitle>
      <PartnersHero
        title="title"
        image={staticQuery.img}
        button={{ title: "test", url: "url" }}
      />

      <LayoutTitle>Partners Hero (Only Title)</LayoutTitle>
      <PartnersHero title="title" image={staticQuery.img} />

      <LayoutTitle>Careers</LayoutTitle>
      <Careers title="test" description="test" />

      <LayoutTitle>Place Map Grid</LayoutTitle>
      <PlacesMapGrid
        places={[
          {
            mapImage: staticQuery.img,
            placeName: "test",
            address: "test",
            link: { title: "test", url: "test" },
          },
          {
            mapImage: staticQuery.img,
            placeName: "test",
            address: "test",
            link: { title: "test", url: "test" },
          },
          {
            mapImage: staticQuery.img,
            placeName: "test",
            address: "test",
            link: { title: "test", url: "test" },
          },
        ]}
      />

      <LayoutTitle>Sliders</LayoutTitle>
      <LocationSlider
        sliders={[
          {
            slider: [
              {
                image: staticQuery.img,
                mapImage: staticQuery.img,
                title: "test",
                subtitle: "test",
                description:
                  "From 200kg industrial robots to 2kg cobots, our Columbus R&D Lab features a huge array of robots.",
              },
              {
                image: staticQuery.img,
                mapImage: staticQuery.img,
                title: "test",
                subtitle: "test",
                description:
                  "From 200kg industrial robots to 2kg cobots, our Columbus R&D Lab features a huge array of robots.",
              },
              {
                image: staticQuery.img,
                mapImage: staticQuery.img,
                title: "test",
                subtitle: "test",
                description:
                  "From 200kg industrial robots to 2kg cobots, our Columbus R&D Lab features a huge array of robots.",
              },
            ],
          },
          {
            slider: [
              {
                image: staticQuery.img,
                mapImage: staticQuery.img,
                title: "test",
                subtitle: "test",
                description:
                  "From 200kg industrial robots to 2kg cobots, our Columbus R&D Lab features a huge array of robots.",
              },
              {
                image: staticQuery.img,
                mapImage: staticQuery.img,
                title: "test",
                subtitle: "test",
                description:
                  "From 200kg industrial robots to 2kg cobots, our Columbus R&D Lab features a huge array of robots.",
              },
              {
                image: staticQuery.img,
                mapImage: staticQuery.img,
                title: "test",
                subtitle: "test",
                description:
                  "From 200kg industrial robots to 2kg cobots, our Columbus R&D Lab features a huge array of robots.",
              },
            ],
          },
          {
            slider: [
              {
                image: staticQuery.img,
                mapImage: staticQuery.img,
                title: "test",
                subtitle: "test",
                description:
                  "From 200kg industrial robots to 2kg cobots, our Columbus R&D Lab features a huge array of robots.",
              },
              {
                image: staticQuery.img,
                mapImage: staticQuery.img,
                title: "test",
                subtitle: "test",
                description:
                  "From 200kg industrial robots to 2kg cobots, our Columbus R&D Lab features a huge array of robots.",
              },
              {
                image: staticQuery.img,
                mapImage: staticQuery.img,
                title: "test",
                subtitle: "test",
                description:
                  "From 200kg industrial robots to 2kg cobots, our Columbus R&D Lab features a huge array of robots.",
              },
            ],
          },
        ]}
      />

      <LayoutTitle>Hero Form</LayoutTitle>
      <HeroForm
        titleForm="test"
        buttonName="test"
        linkPrivacyPolicy={{ title: "test", url: "test" }}
        backgroundImage={staticQuery.img}
      />

      <LayoutTitle>Highlights (Title + Subtitle + Description)</LayoutTitle>
      <Highlights
        title="test"
        subtitle="test"
        description="test"
        items={[
          { title: "test", description: "test" },
          {
            title: "test",
            description: "test",
          },
          { title: "test", description: "test" },
        ]}
      />

      <LayoutTitle>Highlights (Title + Subtitle)</LayoutTitle>
      <Highlights
        title="test"
        subtitle="test"
        items={[
          { title: "test", description: "test" },
          {
            title: "test",
            description: "test",
          },
          { title: "test", description: "test" },
        ]}
      />

      <LayoutTitle>Highlights (Title)</LayoutTitle>
      <Highlights
        title="test"
        items={[
          { title: "test", description: "test" },
          {
            title: "test",
            description: "test",
          },
          { title: "test", description: "test" },
        ]}
      />

      <LayoutTitle>Highlights (Description)</LayoutTitle>
      <Highlights
        description="test"
        items={[
          { title: "test", description: "test" },
          {
            title: "test",
            description: "test",
          },
          { title: "test", description: "test" },
        ]}
      />

      <LayoutTitle>Forge Experience Section</LayoutTitle>
      <ForgeExperienceSection
        title="Forge/OS Demo"
        description="Learn how Forge/OS makes leading robots easy to use, enables seamless control of the entire workcell, and empowers anyone on the production floor to program and manage automation."
        bottomDescription="Learn how Forge/OS makes leading robots easy to use, enables seamless control of the entire workcell, and empowers anyone on the production floor to program and manage automation."
        button={{ title: "test", url: "test" }}
      />

      <LayoutTitle>Forge Video Hero</LayoutTitle>
      <ForgeVideoHero
        title="Experience Forge/OS"
        leftTitle="test"
        leftDescription="Watch a pre-recorded Forge/OS demo and see how Forge/OS makes robots easy to use, enables seamless control of the entire workcell, and empowers your team to win with automation."
        leftLink={buttonObj}
        rightTitle="test"
        rightDescription="Watch a pre-recorded Forge/OS demo and see how Forge/OS makes robots easy to use, enables seamless control of the entire workcell, and empowers your team to win with automation."
        rightLink={buttonObj}
      />

      <LayoutTitle>Device Control</LayoutTitle>
      <DeviceControl
        title="Test"
        description="test"
        image={staticQuery.img}
        deviceDescriptionItems={[
          {
            deviceDescription: "test",
            titleDescription: "test",
          },
          { deviceDescription: "test", titleDescription: "test" },
          {
            deviceDescription: "test",
            titleDescription: "test",
          },
        ]}
      />

      <LayoutTitle>Device Control (No items)</LayoutTitle>
      <DeviceControl title="Test" description="test" image={staticQuery.img} />

      <LayoutTitle>Contact Banner</LayoutTitle>
      <ContactBanner />

      <LayoutTitle>Robot Image Description</LayoutTitle>
      <RobotImageDescription items={robots} />

      <LayoutTitle>Use Description</LayoutTitle>
      <UseDescription
        image={staticQuery.img}
        title="Sell More Robots by Making Your Robots Easy to Use"
        description="Forge/OS brings cobot ease-of-use to industrial robots so
              your users can choose the right robot for the task. With manufacturers hungry
               to deploy more automation, Forge/OS makes your robots easy enough for anyone on the
               production floor to use and will help accelerate sales."
        link={{ url: "./", title: "Learn More" }}
      />

      <LayoutTitle>Use Description (Row Reverse)</LayoutTitle>
      <UseDescription
        image={staticQuery.img}
        title="Sell More Robots by Making Your Robots Easy to Use"
        description="Forge/OS brings cobot ease-of-use to industrial robots so
              your users can choose the right robot for the task. With manufacturers hungry
               to deploy more automation, Forge/OS makes your robots easy enough for anyone on the
               production floor to use and will help accelerate sales."
        link={{ url: "./", title: "Learn More" }}
        rowReverse
      />

      <LayoutTitle>Forge Os Hero</LayoutTitle>
      <ForgeOsHero
        description="TEST"
        title="TEST"
        image={staticQuery.img}
        logoImage={staticQuery.kawasaki}
      />

      <LayoutTitle>Forge Video</LayoutTitle>
      <ForgeVideo image={staticQuery.img} youtubeId="mRdv4jdleP8" />

      <LayoutTitle>ForgeTabs</LayoutTitle>
      <ForgeTabs
        title="test"
        tabs={[
          {
            name: "Robots Arm",
            title: "Test 1",
            callout1: "Test 1",
            callout2: "Test 1",
            image: staticQuery.img,
          },
          {
            name: "Robots Arm",
            title: "Test 2",
            callout1: "Test 2",
            callout2: "Test 2",
            image: staticQuery.img,
          },
          {
            name: "Robots Arm",
            title: "Test 3",
            callout1: "Test 3",
            callout2: "Test 3",
            image: staticQuery.img,
          },
          {
            name: "Robots Arm",
            title: "Test",
            callout1: "Test",
            callout2: "Test",
            image: staticQuery.img,
          },
        ]}
      />

      <LayoutTitle>Forge Apps</LayoutTitle>
      <ForgeApps
        link={{ url: "./", title: "Coming Soon!" }}
        backgroundImage={staticQuery.img}
        title="test"
        description="test"
        apps={[
          {
            logo: "https://admin-rr.aftershock.agency/wp-content/uploads/2022/01/icon_taskcanvas@15x.svg",
            description: "test",
          },
          {
            logo: "https://admin-rr.aftershock.agency/wp-content/uploads/2022/01/icon_taskcanvas@15x.svg",
            description: "test",
          },
          {
            logo: "https://admin-rr.aftershock.agency/wp-content/uploads/2022/01/icon_taskcanvas@15x.svg",
            description: "test",
          },
          {
            logo: "https://admin-rr.aftershock.agency/wp-content/uploads/2022/01/icon_taskcanvas@15x.svg",
            description: "test",
          },
          {
            logo: "https://admin-rr.aftershock.agency/wp-content/uploads/2022/01/icon_taskcanvas@15x.svg",
            description: "test",
          },
        ]}
      />

      <LayoutTitle>Forge Demo</LayoutTitle>
      <ForgeDemo
        videoUrl="https://readyrobotics.weareshellshock.com/wp-content/themes/JointsWP-CSS-master/assets/videos/forge-experience/module1.mp4"
        titleVideo="Test"
        linkVideo={buttonObj}
        image={staticQuery.img}
        titleImage="Test"
        linkImage={buttonObj}
      />

      <LayoutTitle>Forge Hero</LayoutTitle>
      <ForgeHero
        title="test"
        leftButton={buttonObj}
        rightButton={buttonObj}
        description="<p>Windows ignited the PC Age. iOS unlocked the Mobile Age. Forge/OS 5 will unleash the Automation Age. What will you build on Forge/OS 5?</p>"
      />

      <LayoutTitle>Forge Hero (No buttons)</LayoutTitle>
      <ForgeHero
        title="test"
        description="<p>Windows ignited the PC Age. iOS unlocked the Mobile Age. Forge/OS 5 will unleash the Automation Age. What will you build on Forge/OS 5?</p>"
      />

      <LayoutTitle>Top Brands Info Banner</LayoutTitle>
      <TopBrandsInfoBanner
        title="Build Apps that Scale Across All<br>Top Robot Brands."
        description="Industrial automation is a $200B space, but it’s been off limits to developers. Until now! Forge/OS 5 is the first universal operating system for industrial automation. Developers now have a single, Qt-based platform on which to build apps that scale across top robot brands, peripheral hardware, and software."
        brands={[
          { logo: staticQuery.kawasaki },
          { logo: staticQuery.kawasaki },
          { logo: staticQuery.kawasaki },
          { logo: staticQuery.kawasaki },
          { logo: staticQuery.kawasaki },
          { logo: staticQuery.kawasaki },
          { logo: staticQuery.kawasaki },
        ]}
        link={{
          title: "view case studies",
          url: "/",
        }}
      />

      <LayoutTitle>Info Row</LayoutTitle>
      <InfoRow
        title="Built for Builders"
        description="Built on Qt and C++, the Forge/OS 5 tech stack empowers a wide range of developers to build apps that unlock automation."
        content="Forge/OS 5 comes with drivers for major robot brands, as well as force sensors, cameras, and other hardware. It also offers a comprehensive collection of communication tools, programming libraries and intuitive interfaces for building a wide variety of automation and robotics applications."
        image={staticQuery.bg}
      />

      <LayoutTitle>Ready Banner</LayoutTitle>
      <ReadyBanner
        title="Ready to Build Something Great on Forge/OS 5?"
        description="Built on Qt and C++, the Forge/OS 5 tech stack empowers a wide range of developers to build apps that unlock automation."
        button={{ title: "Get Started", url: "/" }}
      />

      <LayoutTitle>Forge Grid</LayoutTitle>
      <ForgeGrid
        title="test"
        feature={[
          {
            icon: "https://admin-rr.aftershock.agency/wp-content/uploads/2022/01/Robotic-Solutions@15x.svg",
            title: "test",
            description:
              "Rapidly configure custom robotics solutions on the platform where hardware works seamlessly and robots aren’t limited by brand.",
          },
          {
            icon: "https://admin-rr.aftershock.agency/wp-content/uploads/2022/01/Robotic-Solutions@15x.svg",
            title: "test",
            description:
              "Rapidly configure custom robotics solutions on the platform where hardware works seamlessly and robots aren’t limited by brand.",
          },
          {
            icon: "https://admin-rr.aftershock.agency/wp-content/uploads/2022/01/Robotic-Solutions@15x.svg",
            title: "test",
            description:
              "Rapidly configure custom robotics solutions on the platform where hardware works seamlessly and robots aren’t limited by brand.",
          },
          {
            icon: "https://admin-rr.aftershock.agency/wp-content/uploads/2022/01/Robotic-Solutions@15x.svg",
            title: "test",
            description:
              "Rapidly configure custom robotics solutions on the platform where hardware works seamlessly and robots aren’t limited by brand.",
          },
          {
            icon: "https://admin-rr.aftershock.agency/wp-content/uploads/2022/01/Robotic-Solutions@15x.svg",
            title: "test",
            description:
              "Rapidly configure custom robotics solutions on the platform where hardware works seamlessly and robots aren’t limited by brand.",
          },
          {
            icon: "https://admin-rr.aftershock.agency/wp-content/uploads/2022/01/Robotic-Solutions@15x.svg",
            title: "test",
            description:
              "Rapidly configure custom robotics solutions on the platform where hardware works seamlessly and robots aren’t limited by brand.",
          },
          {
            icon: "https://admin-rr.aftershock.agency/wp-content/uploads/2022/01/Robotic-Solutions@15x.svg",
            title: "test",
            description:
              "Rapidly configure custom robotics solutions on the platform where hardware works seamlessly and robots aren’t limited by brand.",
          },
          {
            icon: "https://admin-rr.aftershock.agency/wp-content/uploads/2022/01/Robotic-Solutions@15x.svg",
            title: "test",
            description:
              "Rapidly configure custom robotics solutions on the platform where hardware works seamlessly and robots aren’t limited by brand.",
          },
        ]}
      />

      <LayoutTitle>Service Description</LayoutTitle>

      <ServiceDescription
        title="Empowering Automation for Everyone"
        serviceItems={[
          {
            buttonName: "Button Name 1",
            itemsLeft: [
              { serviceDescription: placeholderText },
              { serviceDescription: placeholderText },
            ],
            itemsRight: [
              { serviceDescription: placeholderText },
              { serviceDescription: placeholderText },
            ],
          },
          {
            buttonName: "Button Name 2",
            itemsLeft: [
              { serviceDescription: placeholderText },
              { serviceDescription: "test" },
            ],
            itemsRight: [
              { serviceDescription: placeholderText },
              { serviceDescription: placeholderText },
            ],
          },
          {
            buttonName: "Button Name 3",
            itemsLeft: [
              { serviceDescription: "test" },
              { serviceDescription: placeholderText },
            ],
            itemsRight: [
              { serviceDescription: placeholderText },
              { serviceDescription: placeholderText },
            ],
          },
        ]}
      />

      <LayoutTitle>Robots Usability</LayoutTitle>
      <RobotsUsability
        image={staticQuery.bg}
        title="Enhance Your Robots’ Usability with Custom Apps"
        description="The Forge SDK facilitates the development of custom applications, running on Forge/OS,
              that help end users get more value from your robots. Create task-specific apps that make it easier for
               end users to program tasks like palletizing, dispensing, material handling, inspection, etc."
      />

      <LayoutTitle>Productivity Description (White Bg)</LayoutTitle>
      <ProductivityDescription
        images={[
          { image: staticQuery.robotImage },
          { image: staticQuery.kawasaki },
          { image: staticQuery.img },
        ]}
        iconImage={staticQuery.img}
        title="Developers"
        description="<p>We welcome developers, OEMs, solution providers, and enterprise customers to develop drivers, custom applications
              that instantly scale across top robot bands.</p>"
        link={{ url: "/", title: "Learn More" }}
        productivityItems={[
          {
            title: "Program Robots No Experience Needed",
            description:
              "Task Canvas revolutionizes robot programming. The conversational, flowchart-based programming interface enables anyone to deploy and manage automation with their existing team.",
          },
          {
            title: "Smartphone-Like Simplicity for Automation",
            description:
              "Task Canvas revolutionizes robot programming. The conversational, flowchart-based programming interface enables anyone to deploy and manage automation with their existing team.",
          },
          {
            title: "Brand Agnostic Automation Operating System",
            description:
              "Task Canvas revolutionizes robot programming. The conversational, flowchart-based programming interface enables anyone to deploy and manage automation with their existing team.",
          },
        ]}
      />

      <LayoutTitle>Productivity Description (Black Bg)</LayoutTitle>
      <ProductivityDescription
        images={[
          { image: staticQuery.robotImage },
          { image: staticQuery.kawasaki },
          { image: staticQuery.img },
        ]}
        iconImage={staticQuery.img}
        title="Developers"
        description="<p>We welcome developers, OEMs, solution providers, and enterprise customers to develop drivers, custom applications
              that instantly scale across top robot bands.</p>"
        link={{ url: "/", title: "Learn More" }}
        backgroundColor="black"
        productivityItems={[
          {
            title: "Program Robots No Experience Needed",
            description:
              "Task Canvas revolutionizes robot programming. The conversational, flowchart-based programming interface enables anyone to deploy and manage automation with their existing team.",
          },
          {
            title: "Smartphone-Like Simplicity for Automation",
            description:
              "Task Canvas revolutionizes robot programming. The conversational, flowchart-based programming interface enables anyone to deploy and manage automation with their existing team.",
          },
          {
            title: "Brand Agnostic Automation Operating System",
            description:
              "Task Canvas revolutionizes robot programming. The conversational, flowchart-based programming interface enables anyone to deploy and manage automation with their existing team.",
          },
        ]}
      />

      <LayoutTitle>Banner Description</LayoutTitle>
      <BannerDescription
        title="title"
        description={placeholderText}
        link={buttonObj}
      />

      <LayoutTitle>Ready Content</LayoutTitle>
      <ReadyContent
        title="Title"
        cards={[
          { title: "test", image: staticQuery.img, link: "/" },
          { title: "test", image: staticQuery.img, link: "/" },
        ]}
      />
    </Layout>
  )
}

export default Layouts

import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container } from "@mui/material"

export const Section = styled(SectionWrapper)`
  background-color: white;
`
export const Wrapper = styled(Container)``
export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  font-weight: 500;
  line-height: ${({ theme }) => theme.typography.pxToRem(48)};
  padding-bottom: 5rem;
`
